import Page from '../classes/Page'

class FAQ extends Page {
  constructor() {
    super({ element: '.content' })
  }

  init() {}

  kill() {}
}

module.exports = FAQ
