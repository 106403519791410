export function debounce(func) {
  var timer
  return function (event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, 100, event)
  }
}

export function removeElement(element) {
  if (typeof element === 'string') {
    element = document.querySelector(element)
  }
  return function () {
    element.parentNode.removeChild(element)
  }
}