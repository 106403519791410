import gsap from 'gsap'
import { removeElement, debounce } from './utils'
import { SplitText } from 'gsap/SplitText'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
gsap.registerPlugin(ScrollSmoother, SplitText)

import Home from './pages/Home'
import Mission from './pages/Mission'
import Ecosystems from './pages/Ecosystems'
import Team from './pages/Team'
import FAQ from './pages/FAQ'
import Greenpaper from './pages/Greenpaper'

class App {
  constructor() {
    this.initContent()

    this.initSmoothScroll()
    this.createPreloader()
    this.createPages()
    this.addEventListeners()
    this.addLinkListeners()
  }

  initContent() {
    this.content = document.querySelector('.content')
    this.lowerContent = document.querySelector('.lower-content')
    this.template = this.content.getAttribute('data-template')
  }

  initSmoothScroll() {
    this.smoother = ScrollSmoother.create({
      smooth: 0.5, // seconds it takes to "catch up" to native scroll position
      normalizeScroll: true,
      effects: true // look for data-speed and data-lag attributes on elements and animate accordingly
    })
    this.smoother.paused(true)
  }

  reinitSmoothScroll() {
    this.smoother = ScrollSmoother.create({
      smooth: 0.5, // seconds it takes to "catch up" to native scroll position
      effects: true // look for data-speed and data-lag attributes on elements and animate accordingly
    })
  }

  createPreloader(dev = false) {
    if (dev == true) {
      gsap.to('.preload__overlay', {
        autoAlpha: 0,
        ease: 'power4.inOut'
      })
      removeElement('.preload__overlay')
      return this.onPreloadComplete()
    }
    let tl = gsap.timeline({ onComplete: () => this.onPreloadComplete() })
    this.smoother.scrollTop(0)

    if (this.template == 'home') {
      let s = new SplitText('.headline', {
        type: 'words,chars',
        charsClass: 'split-chars'
      })

      tl.to('.preload__logo__container svg', {
        top: 0,
        opacity: 1,
        ease: 'power4.inOut',
        duration: 1.6
      })
      .to('.preload__overlay', { autoAlpha: 0, duration: 0.5 })
      .to(s.chars, {
        duration: 0.2,
        opacity: 1,
        y: 10,
        ease: 'power4.inOut',
        stagger: 0.05
      })
      // .to('.preload__logo__container svg', {
      //   opacity: 0,
      //   ease: 'power4.inOut',
      // })
      // .to('.preload__overlay', {
      //   autoAlpha: 0,
      //   ease: 'power4.inOut'
      // })
      // .call(removeElement('.preload__overlay'))
    } else {
      tl.to('.preload__logo__container svg', {
        top: 0,
        opacity: 1,
        ease: 'power4.inOut',
        duration: 1
      })
        .to(['.preload__logo__container svg'], {
          opacity: 0,
          ease: 'power4.inOut',
          stagger: 0.6
        })
        .to('.preload__overlay', {
          autoAlpha: 0,
          ease: 'power4.inOut'
        })
        .call(removeElement('.preload__overlay'))
    }
    //  tl.pause()

    // let timer = setTimeout(() => {
    //   tl.resume()
    // }, 500)
  }

  onPreloadComplete() {
    // if (!this.isTouch) this.createCursor()
    this.createLogo()
    this.createNav()

    // this.createSeeds()
    this.smoother.paused(false)
    // this.initListeners()
  }

  createPages() {
    this.pages = {
      home: new Home(),
      mission: new Mission(),
      ecosystems: new Ecosystems(),
      team: new Team(),
      faqs: new FAQ(),
      greenpaper: new Greenpaper()
    }

    this.page = this.pages[this.template]
    this.page.init()
  }

  createLogo() {
    gsap.to('.header__logo', { opacity: 1 })
  }

  createNav() {
    // add media query here
    // if desktop
    let tl = gsap.timeline()
    tl.fromTo(
      ['.desktop__nav .nav__item'],
      { opacity: 0, y: '-10px' },
      { opacity: 1, y: 0, stagger: 0.16 }
    )
    // else

    this.initMobileNav()
  }

  initMobileNav() {
    this.mobileNav = document.querySelector('.mobile__nav')
    this.mobileNavButton = document.querySelector('.mobile__nav__button')
    this.mobileNavTimeline = gsap.timeline()
    const navSettings = { duration: 0.3, ease: 'power2.inOut' }
    const listSettings = { duration: 0.3, ease: 'power2.Out' }

    let listItems = document.querySelectorAll('.mobile__nav__list .nav__item')

    this.mobileNavTimeline
      .to(this.mobileNav, {
        x: '0',
        ...navSettings
      })
      .to(listItems, { x: '0', ...listSettings, stagger: 0.05 }, '<+=0.1')
      .pause()

    this.mobileNavButton.addEventListener('click', () => this.toggleMobileNav())
  }

  updateMobileNav() {
    if (this.mobileNav) {
      return this.mobileNavTimeline
        .to(this.mobileNav, {
          x: '0',
          ...navSettings
        })
        .to(listItems, { x: '0', ...listSettings, stagger: 0.05 }, '<+=0.1')
        .pause()
    }
  }

  toggleMobileNav() {
    if (this.mobileNav.classList.contains('open')) {
      this.mobileNavTimeline.reverse()
      this.mobileNav.classList.remove('open')
      this.mobileNavButton.classList.remove('open')
    } else {
      this.mobileNavTimeline.play()
      this.mobileNav.classList.add('open')
      this.mobileNavButton.classList.add('open')
    }
  }

  createCursor() {
    var ball = document.querySelector('.ball')
    gsap.set(ball, { xPercent: -50, yPercent: -50 })

    var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    var mouse = { x: pos.x, y: pos.y }
    var speed = 0.25
    var fpms = 60 / 1000
    var xSet = gsap.quickSetter(ball, 'x', 'px')
    var ySet = gsap.quickSetter(ball, 'y', 'px')

    var showAnimation = gsap.to(ball, {
      opacity: 1,
      paused: true
    })

    var timer = gsap
      .delayedCall(5, () => {
        showAnimation.reverse()
      })
      .pause()

    window.addEventListener('mousemove', init)

    function init() {
      window.removeEventListener('mousemove', init)
      window.addEventListener('mousemove', (e) => {
        mouse.x = e.x
        mouse.y = e.y
      })
      document.addEventListener('mouseleave', (e) => {
        showAnimation.reverse()
      })
      document.addEventListener('mouseenter', (e) => {
        showAnimation.play()
        mouse.x = pos.x = e.x
        mouse.y = pos.y = e.y
      })

      gsap.ticker.add((time, deltaTime) => {
        var delta = deltaTime * fpms
        var dt = 1.0 - Math.pow(1.0 - speed, delta)
        pos.x += (mouse.x - pos.x) * dt
        pos.y += (mouse.y - pos.y) * dt
        xSet(pos.x)
        ySet(pos.y)
      })
      showAnimation.play()
    }
  }

  async onChange({ url, push = true }) {
    // await this.page.hide()
    const res = await window.fetch(url)

    if (res.status === 200) {
      if (this.page.kill) this.page.kill() // prevent gsap target not found errors
      const html = await res.text()
      const div = document.createElement('div')

      if (push) window.history.pushState({}, '', url)

      div.innerHTML = html

      const divContent = div.querySelector('.content')
      this.template = divContent.getAttribute('data-template')

      // this.navigation.onChange(this.template);

      this.content.setAttribute('data-template', this.template)
      this.content.innerHTML = divContent.innerHTML

      this.page = this.pages[this.template]
      this.page.init()

      this.onResize()
      this.reinitSmoothScroll()
      this.smoother.scrollTop(0)
      this.page.show()

      this.addLinkListeners()
    } else {
      console.error(`response status: ${res.status}`)
    }
  }

  addLinkListeners() {
    const isExternalURL = (url) => new URL(url).origin !== location.origin
    const links = document.querySelectorAll('a')

    links.forEach((link) => {
      link.onclick = (event) => {
        const { href } = link

        if (isExternalURL(href)) {
          return
        }
        event.preventDefault()
        if (this.mobileNavButton.classList.contains('open'))
          this.toggleMobileNav()

        this.onChange({ url: href })
      }
    })
  }

  onPopState() {
    this.onChange({ url: window.location.pathname, push: false })
  }

  addEventListeners() {
    window.addEventListener('popstate', () => this.onPopState())
    // Media query
    window.addEventListener(
      'resize',
      debounce(() => {
        this.onResize()
      })
    )
    this.onResize()
  }

  onResize() {
    this.isMobile = window.matchMedia('screen and (max-width: 768px)').matches
      ? true
      : false

    this.isTouch = 'ontouchstart' in document.documentElement
  }

  // createForm() {
  //   const handleSubmit = (e) => {
  //     e.preventDefault()
  //     let myForm = document.querySelector('form')
  //     let formData = new FormData(myForm)

  //     fetch('/', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //       body: new URLSearchParams(formData).toString()
  //     })
  //       .then(() => onSuccess())
  //       .catch((error) => alert(error))
  //   }

  //   const onSuccess = () => {
  //     let tl = gsap.timeline()
  //     tl.set('.interest__warp', {
  //       display: 'flex'
  //     })
  //       .set(['.cta__header, .interest__input', '.interest__button'], {
  //         opacity: 0
  //       })
  //       .to('.interest__warp', {
  //         scale: 1,
  //         duration: 1.4,
  //         autoAlpha: 1,
  //         ease: 'elastic.out(1, 0.3)'
  //       })
  //   }

  //   document
  //     .querySelector('#interest_form')
  //     .addEventListener('submit', handleSubmit)
  // }
}

new App()
