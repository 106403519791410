import Page from '../classes/Page'

import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger, SplitText)

class Home extends Page {
  constructor() {
    super({ element: '.content' })
  }

  init() {
    this.createWorlds()
    this.createHow()
    this.createEcosystems()
    const panels = gsap.utils.toArray('.panel:not(.intro)')
    panels.forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        start: 'top top',
        pin: true,
        pinSpacing: false
      })
    })
  }

  kill() {
    ScrollTrigger.getAll().forEach((s) => s.kill())
  }

  createWorlds() {
    gsap.set('.physical', {
      position: 'absolute',
      top: '50%',
      left: '20%',
      xPercent: -50,
      yPercent: -50,
      scale: 0.2
      // autoAlpha: 0
    })
    gsap.set('.digital', {
      position: 'absolute',
      top: '50%',
      left: '80%',
      xPercent: -50,
      yPercent: -50,
      scale: 0.2
      // autoAlpha: 0
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.bridges',
          start: 'top center', // when the top of the trigger hits the middle of the viewport
          end: '70%',
          scrub: 0.3
          // fastScrollEnd: true
          // preventOverlaps: true,
          // markers: true
        }
      })
      .to('.physical', {
        autoAlpha: 1,
        left: '50%',
        scale: 1,
        duration: 0.3
      })
      .to(
        '.digital',
        {
          autoAlpha: 1,
          left: '50%',
          scale: 1,
          duration: 0.3
        },
        '<'
      )
      .set('.digital', {
        autoAlpha: 0
      })
      .to('.physical', {
        keyframes: [
          { scale: 0.3, duration: 0.2 },
          {
            autoAlpha: 0,
            duration: 0.1
          }
        ]
      })
  }

  createHow() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.how',
          start: 'top center', // when the top of the trigger hits the middle of the viewport
          end: 'bottom bottom',
          scrub: true
        }
      })
      .from('.how__step', { autoAlpha: 0, stagger: 2 })
      .from('.how__step > *', { autoAlpha: 0, y: 20, stagger: 1 })
  }

  createEcosystems() {
    gsap.set('.nonprofit__item', { y: 10 })

    // gsap.set(ecosystems, {
    //   transformOrigin: `50% 50%`,
    //   position: 'absolute',
    //   top: '50%',
    //   left: '50%',
    //   xPercent: -50,
    //   yPercent: -50
    // })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.ecos',
          start: 'top top', // when the top of the trigger hits the middle of the viewport
          end: 'bottom',
          scrub: true
        }
      })
      .from('.ecosystems__portal', { scale: 0.4, duration: 0.1 })
      .to('.ecosystems__strip', {
        left: '70vw',
        duration: 0.9
      })
      .set('.ecos .label', { opacity: 1, duration: 0.1 }, '<')

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.nonprofits',
          start: 'top top', // when the top of the trigger hits the middle of the viewport
          end: '80%',
          scrub: true
        }
      })
      .to('.nonprofit__item', {
        keyframes: [
          { opacity: 1, duration: 0.1 },
          { opacity: 0, duration: 0.05 }
        ],
        stagger: 0.2
      })
      .set('.nonprofits .label', { opacity: 1 }, '<')

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.world',
          start: 'top top', // when the top of the trigger hits the middle of the viewport
          end: 'bottom top',
          scrub: true
        }
      })
      .to('.world__portal', { scale: 0.4, yPercent: -20, duration: 0.6 })
      .to(
        '.world .label',
        {
          keyframes: [
            { opacity: 1, duration: 0.5 },
            { yPercent: 140, delay: -0.5 },
            { opacity: 0, delay: 0.5 }
          ]
        },
        '<60%'
      )
  }
}

module.exports = Home
