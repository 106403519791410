import Page from '../classes/Page'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

class Ecosystems extends Page {
  constructor() {
    super({ element: '.content' })
  }

  init() {
    this.createEcosystemSection()
  }

  kill() {
    ScrollTrigger.getAll().forEach((s) => s.kill())
  }

  createEcosystemSection() {
    let ecosystems = document.querySelectorAll('.ecosystem__container')

    ecosystems.forEach((ecosystem) => {
      let header = ecosystem.querySelector('.ecosystem__header')
      let infoItems = ecosystem.querySelectorAll('.info__item')
      let artist = ecosystem.querySelector('.ecosystem__artist')

      let artistImageContainer = ecosystem.querySelector(
        '.team__image__container'
      )
      let artistImage = ecosystem.querySelector('.team__image__clip')
      let artistName = ecosystem.querySelector('.artist__name')
      let artistStats = ecosystem.querySelectorAll('.artist__stats *')

      gsap.set([artist, artistImage, artistName], { opacity: 0 })
      gsap.set(artistStats, { opacity: 0 })
      gsap.set(artistImageContainer, { scale: 0 })

      let artistCard = () =>
        gsap
          .timeline()
          .to(artist, { opacity: 1, duration: 0.5 })
          .to(artistName, { opacity: 1, duration: 0.2 })
          .to(artistImageContainer, {
            scale: 1,
            duration: 0.2,
            ease: 'power1.inOut'
          })
          .to(artistImage, { opacity: 1, duration: 0.2 })
          .to(artistStats, { opacity: 1, duration: 0.2, stagger: 0.05 })

      gsap.set(header, { y: 10, opacity: 0 })
      gsap.set(ecosystem, { xPercent: -100 })
      gsap.set(infoItems, { opacity: 0, y: 10 })

      let infoItemsIn = () =>
        gsap
          .timeline()
          .to(header, { y: 0, opacity: 1, duration: 0.3 })
          .to(infoItems, { opacity: 1, y: 0, duration: 0.2, stagger: 0.05 })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: ecosystem,
            start: 'top 50%', // when the top of the trigger hits the middle of the viewport
            end: '30% 50%',
            scrub: 0.5
            // markers: true
          }
        })
        // .from(header, { opacity: 0, y: 20, duration: 0.3 })
        .to(ecosystem, { xPercent: 0, duration: 4, ease: 'expo.Out' })
        .call(() => infoItemsIn(), null, '<65%')
        .call(() => artistCard())
    })
  }
}

module.exports = Ecosystems
