import gsap from 'gsap'

export default class Page {
  constructor({ element }) {
    this.element = element
  }

  show(animation) {
    return new Promise((resolve) => {
      if (animation) {
        this.animationIn = animation
      } else {
        this.animationIn = gsap.timeline()
        this.animationIn.fromTo(
          this.element,
          {
            autoAlpha: 0
          },
          {
            autoAlpha: 1
          }
        )
      }

      this.animationIn.call(() => {
        this.addEventListeners()

        resolve()
      })
    })

  }

  hide() {
    return new Promise((resolve) => {
      this.destroy()

      gsap.timeline().to(this.element, {
        autoAlpha: 0,
        onComplete: resolve
      })
    })
  }

  addEventListeners() {}
}
