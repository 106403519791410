import Page from '../classes/Page'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

class Mission extends Page {
  constructor() {
    super({ element: '.content' })
  }

  init() {
  }

  kill() {
    ScrollTrigger.getAll().forEach((s) => s.kill())
  }


}

module.exports = Mission
