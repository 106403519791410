import Page from '../classes/Page'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class Greenpaper extends Page {
  constructor() {
    super({ element: '.content' })
  }

  init() {}

  kill() {}
}

module.exports = Greenpaper
